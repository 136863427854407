import Dexie, { Table } from "dexie";

interface User {
  uid: string;
  email: string;
}

class MyDatabase extends Dexie {
  users!: Table<User, string>;

  constructor() {
    super("myDatabase");
    this.version(1).stores({
      users: "uid, email",
    });
  }
}

export const db = new MyDatabase();
