<template>
  <div class="full-screen-container p-d-flex p-flex-column p-ai-center p-p-4">
    <div
      class="p-d-flex p-jc-center p-ai-center p-w-full p-py-2 header"
      style="color: white"
    >
      <div class="p-text-bold">Inventarsystem</div>
      <div class="id-text">Entwurf Version 0.0.1 Click Dummy</div>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Start"
          class="p-button-rounded p-button-primary"
        />
      </router-link>
    </div>

    <div class="p-d-flex p-flex-column p-ai-center p-mt-5 section">
      <h3 style="color: #fff">Equipment</h3>
      <router-link to="/addequip">
        <Button
          label="Equipment anlegen"
          icon="pi pi-plus"
          class="p-button-primary button-margin"
        />
      </router-link>

      <router-link to="/manageequip">
        <Button
          label="Equipment verwalten"
          icon="pi pi-plus"
          class="p-button-primary button-margin"
        />
      </router-link>

      <router-link to="/searchequip">
        <Button
          label="Equipment suchen"
          icon="pi pi-search"
          class="p-button-primary button-margin"
        />
      </router-link>
    </div>

    <div class="p-d-flex p-flex-column p-ai-center p-mt-5 section">
      <h3 style="color: #fff">Einsatz</h3>
      <router-link to="/operation">
        <Button
          label="Übersicht"
          icon="pi pi-list"
          class="p-button-primary button-margin"
        />
      </router-link>
      <router-link to="/registerequip">
        <Button
          label="Eintragen"
          icon="pi pi-sign-in"
          class="p-button-primary button-margin"
        />
      </router-link>
      <router-link to="/finishequip">
        <Button
          label="Austragen"
          icon="pi pi-sign-out"
          class="p-button-primary button-margin"
        />
      </router-link>
    </div>

    <div class="p-d-flex p-flex-column p-ai-center p-mt-5 section">
      <h3 style="color: #fff">Einstellungen</h3>
      <Button
        label="Einstellungen"
        icon="pi pi-cog"
        class="p-button-primary button-margin"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  components: {
    Button,
  },
});
</script>

<style scoped>
.full-screen-container {
  width: 100vw;
  height: 100vh;
  background-color: #141d2b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
}

.id-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  white-space: nowrap;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.button-margin {
  margin: 10px;
}

.p-button {
  width: 200px;
  justify-content: center;
}
</style>
