<template>
  <div class="container">
    <div class="header w-full">
      <h1>Inventarsystem</h1>
      <div class="id-display">Entwurf Version 0.0.1 Click Dummy</div>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Start"
          class="p-button-rounded p-button-primary"
        />
      </router-link>
    </div>

    <form class="form">
      <h2>Basisinformationen</h2>

      <div class="p-grid">
        <div class="p-col-6 form-group">
          <label>Equipment ID</label>
          <input
            type="text"
            v-model="form.equipmentId"
            placeholder="Equipment ID"
          />
        </div>
        <div class="p-col-6 form-group">
          <label>Fachgruppe</label>
          <select v-model="form.fachgruppe">
            <option v-for="option in fachgruppen" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label>Equipmentbezeichnung</label>
        <input
          type="text"
          v-model="form.equipmentBezeichnung"
          placeholder="Equipmentbezeichnung"
        />
      </div>

      <h2>Anschlussinformationen</h2>

      <div class="p-grid">
        <div class="p-col-6 form-group">
          <label>Eingang</label>
          <select v-model="form.eingang">
            <option
              v-for="option in eingangOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="p-col-6 form-group">
          <label>Größe</label>
          <select v-model="form.groesse">
            <option
              v-for="option in groesseOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="p-col-6 form-group">
          <label>Länge</label>
          <select v-model="form.laenge">
            <option
              v-for="option in laengeOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="p-col-6 form-group">
          <label>Ausgang Schuko</label>
          <select v-model="form.ausgangSchuko">
            <option
              v-for="option in ausgangSchukoOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="p-col-6 form-group">
          <label>Ausgang 16 A</label>
          <select v-model="form.ausgang16A">
            <option
              v-for="option in ausgang16AOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="p-col-6 form-group">
          <label>Ausgang 32 A</label>
          <select v-model="form.ausgang32A">
            <option
              v-for="option in ausgang32AOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="p-col-6 form-group">
          <label>Ausgang 63 A</label>
          <select v-model="form.ausgang63A">
            <option
              v-for="option in ausgang63AOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="p-col-6 form-group">
          <label>Ausgang 125 A</label>
          <select v-model="form.ausgang125A">
            <option
              v-for="option in ausgang125AOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>

      <h2>Zusatzinformationen</h2>

      <div class="p-grid">
        <div class="p-col-6 form-group">
          <label>Lagerort</label>
          <select v-model="form.lagerort">
            <option
              v-for="option in lagerortOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
        <div class="p-col-6 form-group">
          <label>Lagerplatz</label>
          <select v-model="form.lagerplatz">
            <option
              v-for="option in lagerplatzOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <div class="p-col-6 form-group">
          <label>Zustand</label>
          <select v-model="form.zustand">
            <option
              v-for="option in zustandOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>

      <div class="buttons">
        <button type="button" class="clear-btn" @click="clearForm">
          leeren
        </button>
        <button type="submit" class="submit-btn" @click.prevent="submitForm">
          anlegen
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import Button from "primevue/button";

const form = reactive({
  equipmentId: "",
  fachgruppe: "",
  equipmentBezeichnung: "",
  eingang: "",
  groesse: "",
  laenge: "",
  ausgangSchuko: "",
  ausgang16A: "",
  ausgang32A: "",
  ausgang63A: "",
  ausgang125A: "",
  lagerort: "",
  lagerplatz: "",
  zustand: "",
});

const fachgruppen = ["Elektrik", "Mechanik", "Software"];
const eingangOptions = ["Eingang 1", "Eingang 2"];
const groesseOptions = ["Klein", "Mittel", "Groß"];
const laengeOptions = ["1m", "5m", "10m"];
const ausgangSchukoOptions = ["Schuko 1", "Schuko 2"];
const ausgang16AOptions = ["16A 1", "16A 2"];
const ausgang32AOptions = ["32A 1", "32A 2"];
const ausgang63AOptions = ["63A 1", "63A 2"];
const ausgang125AOptions = ["125A 1", "125A 2"];
const lagerortOptions = ["Lager A", "Lager B", "Lager C"];
const lagerplatzOptions = ["Platz 1", "Platz 2", "Platz 3"];
const zustandOptions = ["Neu", "Gebraucht", "Defekt"];

const clearForm = () => {
  Object.keys(form).forEach((key) => {
    form[key] = "";
  });
};

const submitForm = () => {
  console.log("Form submitted:", form);
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1d1d2e;
  color: white;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h1,
h2 {
  text-align: center;
}

.id-display {
  font-size: 12px;
  color: #ccc;
}

.start-btn {
  background-color: #1a73e8;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

input,
select {
  padding: 8px;
  border-radius: 4px;
  border: none;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clear-btn,
.submit-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-btn {
  background-color: #f44336;
  color: white;
}

.submit-btn {
  background-color: #4caf50;
  color: white;
}
</style>
