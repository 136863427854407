import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCD7-avsN3bpwmrvEsSGGQiP3wZ80GztBU",
  authDomain: "thwginventarsystem.firebaseapp.com",
  projectId: "thwginventarsystem",
  storageBucket: "thwginventarsystem.appspot.com",
  messagingSenderId: "442512012780",
  appId: "1:442512012780:web:28515d6194f5beabb3bcc9",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
