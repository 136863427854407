<template>
  <div>
    <div class="header">
      <h1>Inventarsystem</h1>
      <div class="id-display">Entwurf Version 0.0.1 Click Dummy</div>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Start"
          class="p-button-rounded p-button-primary"
        />
      </router-link>
    </div>
    <div>
      <div class="dropdown">
        <label for="empfanger">Empfänger</label>
        <select v-model="selectedEmpfanger">
          <option v-for="option in empfangerOptions" :key="option">
            {{ option }}
          </option>
        </select>
        <button @click="clearEmpfanger">leeren</button>
      </div>
      <div class="tags">
        <label for="verwendungszweck">Verwendungszweck</label>
        <div v-for="tag in verwendungszweckOptions" :key="tag" class="tag">
          {{ tag }}
        </div>
      </div>
      <div class="scan">
        <label for="equipmentId">Scanne die ID</label>
        <input v-model="equipmentId" placeholder="Equipment ID" />
        <button @click="clearEquipmentId">leeren</button>
      </div>
      <div class="dropdown">
        <label for="mitarbeiter">Mitarbeiter</label>
        <select v-model="selectedMitarbeiter">
          <option v-for="option in mitarbeiterOptions" :key="option">
            {{ option }}
          </option>
        </select>
        <button @click="clearMitarbeiter">leeren</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const selectedEmpfanger = ref("");
const selectedMitarbeiter = ref("");
const equipmentId = ref("");

const empfangerOptions = ["THW-OV-GF", "THW", "Feuerwehr", "DRK"];
const mitarbeiterOptions = ["Meier", "Müller", "Schmidt", "Schneider"];
const verwendungszweckOptions = [
  "Nord",
  "Ost",
  "Süd",
  "West",
  "KG.",
  "EG.",
  "OG.",
  "DG.",
  "Bel.",
  "Pumpe",
  "Antrieb",
  "&",
];

const clearEmpfanger = () => {
  selectedEmpfanger.value = "";
};

const clearMitarbeiter = () => {
  selectedMitarbeiter.value = "";
};

const clearEquipmentId = () => {
  equipmentId.value = "";
};
</script>

<style scoped>
.dropdown,
.tags,
.scan {
  margin-bottom: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.tag {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: #2d42a5;
  color: #f0f0f0;
  border-radius: 5px;
}
</style>
