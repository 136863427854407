<template>
  <div class="flex flex-column align-items-center p-5">
    <!-- Header -->
    <div class="header w-full">
      <h1>Inventarsystem</h1>
      <div class="id-display">Entwurf Version 0.0.1 Click Dummy</div>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Start"
          class="p-button-rounded p-button-primary"
        />
      </router-link>
    </div>

    <div class="p-4 w-full">
      <DataTable
        :value="equipments"
        paginator
        :rows="10"
        :filters="filters"
        filterDisplay="menu"
        :globalFilterFields="[
          'equipmentId',
          'equipmentBezeichnung',
          'fachgruppe',
          'zustand',
        ]"
        class="w-full"
      >
        <template #header>
          <div class="flex justify-between">
            <InputText
              v-model="filters['global'].value"
              placeholder="Suche nach allem..."
            />
          </div>
        </template>

        <Column
          field="equipmentId"
          header="Equipment ID"
          filter
          filterPlaceholder="Suche ID"
          style="min-width: 10rem"
        />
        <Column
          field="equipmentBezeichnung"
          header="Bezeichnung"
          filter
          filterPlaceholder="Suche Bezeichnung"
          style="min-width: 14rem"
        />
        <Column
          field="fachgruppe"
          header="Fachgruppe"
          filter
          filterPlaceholder="Suche Fachgruppe"
          style="min-width: 12rem"
        />
        <Column field="eingang" header="Eingang" style="min-width: 10rem" />
        <Column field="groesse" header="Größe" style="min-width: 10rem" />
        <Column field="laenge" header="Länge" style="min-width: 10rem" />
        <Column
          field="ausgangSchuko"
          header="Ausgang Schuko"
          style="min-width: 12rem"
        />
        <Column
          field="zustand"
          header="Zustand"
          filter
          filterPlaceholder="Suche Zustand"
          style="min-width: 10rem"
        />
        <template #empty> Kein Equipment gefunden. </template>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { InputText } from "primevue/inputtext";
import Button from "primevue/button";
import { FilterMatchMode } from "primevue/api";

const equipments = ref([
  {
    equipmentId: "EQUIP001",
    equipmentBezeichnung: "Bohrmaschine",
    fachgruppe: "Mechanik",
    eingang: "Eingang 1",
    groesse: "Mittel",
    laenge: "5m",
    ausgangSchuko: "Schuko 1",
    zustand: "Neu",
  },
  {
    equipmentId: "EQUIP002",
    equipmentBezeichnung: "Laptop",
    fachgruppe: "Software",
    eingang: "Eingang 2",
    groesse: "Klein",
    laenge: "1m",
    ausgangSchuko: "Schuko 2",
    zustand: "Gebraucht",
  },
  {
    equipmentId: "EQUIP003",
    equipmentBezeichnung: "Verlängerungskabel",
    fachgruppe: "Elektrik",
    eingang: "Eingang 1",
    groesse: "Groß",
    laenge: "10m",
    ausgangSchuko: "Schuko 1",
    zustand: "Defekt",
  },
]);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  equipmentId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  equipmentBezeichnung: { value: null, matchMode: FilterMatchMode.CONTAINS },
  fachgruppe: { value: null, matchMode: FilterMatchMode.CONTAINS },
  zustand: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.id-display {
  font-size: 14px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

.p-4 {
  border-radius: 10px;
  padding: 20px;
}

.submit-btn {
  background-color: #4caf50;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #45a049;
}

.w-full {
  width: 100%;
}

.mr-3 {
  margin-right: 1rem;
}
</style>
