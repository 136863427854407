<template>
  <div class="full-screen-container">
    <!-- Header -->
    <div
      class="p-d-flex p-jc-center p-ai-center p-w-full p-py-2 header"
      style="color: white"
    >
      <div class="p-text-bold">Inventarsystem</div>
      <div class="id-text">Entwurf Version 0.0.1 Click Dummy</div>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Start"
          class="p-button-rounded p-button-primary"
        />
      </router-link>
    </div>
    <div
      class="p-d-flex p-flex-column p-ai-center p-p-4"
      style="background-color: #141d2b; flex-grow: 1; min-height: 0"
    >
      <div
        class="p-d-flex p-flex-column p-ai-center p-p-4 p-mt-3"
        style="color: white; width: 100%"
      >
        <h3>Einsatzübersicht</h3>
        <Dropdown
          v-model="selectedEinsatz"
          :options="einsatzOptions"
          optionLabel="name"
          placeholder="Einsatz auswählen"
          class="p-mb-3 p-d-block p-fluid"
        ></Dropdown>

        <div class="einsatz-container p-d-flex p-flex-column p-ai-center">
          <div
            v-for="einsatz in einsaetze"
            :key="einsatz.id"
            class="einsatz-item p-card"
          >
            <div
              class="einsatz-header p-d-flex p-ai-center p-py-2"
              style="border-bottom: 1px solid #fff"
            >
              <h4 style="color: white; margin: 0">{{ einsatz.name }}</h4>
            </div>
            <div
              class="einsatz-details p-d-flex p-jc-between p-ai-center"
              style="padding: 10px"
            >
              <div
                class="p-d-flex p-ai-center p-jc-between"
                style="width: 100%"
              >
                <div class="p-d-flex p-ai-center">
                  <span
                    :style="{
                      color: einsatz.status === 'Aktiv' ? 'green' : 'red',
                    }"
                    style="margin-right: 20px"
                  >
                    {{ einsatz.status }}
                  </span>
                  <p style="color: white; margin: 0; margin-right: 20px">
                    {{ einsatz.date }}
                  </p>
                </div>

                <div class="p-d-flex" style="gap: 10px">
                  <Button
                    icon="pi pi-trash"
                    class="p-button-danger p-button-rounded"
                    @click="deleteEinsatz(einsatz.id)"
                  />
                  <Button
                    icon="pi pi-download"
                    class="p-button-rounded p-button-help"
                    @click="downloadEinsatz(einsatz.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

export default {
  components: {
    Dropdown,
    Button,
  },
  setup() {
    const selectedEinsatz = ref(null);
    const einsatzOptions = [
      { name: "Einsatz 1", id: 1 },
      { name: "Einsatz 2", id: 2 },
    ];
    const einsaetze = ref([
      {
        id: 1,
        name: "Einsatz 1",
        status: "Aktiv",
        date: "08.09.2024 12:00 Uhr",
      },
      {
        id: 2,
        name: "Einsatz 2",
        status: "Beendet",
        date: "08.09.2024 12:00 Uhr",
      },
    ]);

    const deleteEinsatz = (id) => {
      console.log("Delete operation with id:", id);
    };

    const downloadEinsatz = (id) => {
      console.log("Download operation with id:", id);
    };

    return {
      selectedEinsatz,
      einsatzOptions,
      einsaetze,
      deleteEinsatz,
      downloadEinsatz,
    };
  },
};
</script>

<style scoped>
.full-screen-container {
  width: 100vw;
  height: 100vh;
  background-color: #141d2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
}

.id-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  white-space: nowrap;
}

.p-card {
  border-radius: 8px;
  border: none;
}

.p-card .pi {
  font-size: 1.5em;
}

.p-button-rounded {
  width: 2.5rem;
  height: 2.5rem;
}

.einsatz-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem; /* Abstand zwischen Dropdown und Einsätzen */
}

.einsatz-item {
  border-radius: 8px;
  border: none;
  background-color: #1e2a3a;
  margin-top: 2rem;
}

.einsatz-header {
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}

.einsatz-details {
  padding-top: 10px;
}

.einsatz-details .p-d-flex {
  gap: 10px; /* Abstand zwischen den Buttons und den Details */
}
</style>
