import { defineStore } from "pinia";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import { db } from "../db";

interface User {
  uid: string;
  email: string;
}

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null as User | null,
    loading: true,
  }),
  actions: {
    async login(email: string, password: string) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = {
          uid: userCredential.user.uid,
          email: userCredential.user.email as string,
        };
        this.user = user;

        await db.users.put(user);
      } catch (error) {
        console.error("Login fehlgeschlagen", error);
      }
    },
    async logout() {
      await signOut(auth);
      this.user = null;

      await db.users.clear();
    },
    async init() {
      const savedUser = await db.users.toArray();
      if (savedUser.length > 0) {
        this.user = savedUser[0];
      }
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.user = {
            uid: user.uid,
            email: user.email as string,
          };
        } else {
          this.user = null;
        }
        this.loading = false;
      });
    },
  },
});
