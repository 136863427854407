<template>
  <div class="flex flex-column align-items-center p-5">
    <!-- Header -->
    <div class="header">
      <h1>Inventarsystem</h1>
      <div class="id-display">Entwurf Version 0.0.1 Click Dummy</div>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Start"
          class="p-button-rounded p-button-primary"
        />
      </router-link>
    </div>

    <div class="p-4 border-round shadow-2 w-full md:w-6">
      <h3 class="mb-3">Trage eine ID ein.</h3>

      <div class="mb-4">
        <label for="equipment-id" class="">Equipment ID</label>
        <InputText
          id="equipment-id"
          v-model="equipmentId"
          class="w-full"
          placeholder="Equipment ID"
        />
      </div>

      <Button
        label="Equipment suchen"
        icon="pi pi-search"
        class="w-full"
        @click="submitForm"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

const equipmentId = ref("");

const selectedZustand = ref(null);

const submitForm = () => {
  console.log("Equipment ID:", equipmentId.value);
  console.log("Zustand:", selectedZustand.value);
};
</script>

<style scoped>
.text-white {
  color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.id-display {
  color: white;
  font-size: 14px;
}

.surface-ground {
  background-color: rgba(31, 42, 64, 0.9);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
}

h1,
h3 {
  color: white;
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
