import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import Login from "../views/LoginView.vue";
import { useAuthStore } from "../stores/authStore";
import AddEquipView from "@/views/AddEquipView.vue";
import OperationView from "@/views/OperationView.vue";
import RegisterEquipView from "@/views/RegisterEquipView.vue";
import FinishEquipView from "@/views/FinishEquipView.vue";
import SearchEquipView from "@/views/SearchEquipView.vue";
import ManageEquipView from "@/views/ManageEquipView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/operation",
    name: "Operation",
    component: OperationView,
    meta: { requiresAuth: true },
  },
  {
    path: "/addequip",
    name: "AddEquip",
    component: AddEquipView,
    meta: { requiresAuth: true },
  },
  {
    path: "/registerequip",
    name: "RegisterEquip",
    component: RegisterEquipView,
    meta: { requiresAuth: true },
  },
  {
    path: "/finishequip",
    name: "FinishEquip",
    component: FinishEquipView,
    meta: { requiresAuth: true },
  },
  {
    path: "/searchequip",
    name: "searchequip",
    component: SearchEquipView,
    meta: { requiresAuth: true },
  },
  {
    path: "/manageequip",
    name: "manageequip",
    component: ManageEquipView,
    meta: { requiresAuth: true },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (authStore.loading) {
    await authStore.init();
  }

  const isAuthenticated = !!authStore.user;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    if (to.name === "Login" && isAuthenticated) {
      next({ name: "Home" });
    } else {
      next();
    }
  }
});

export default router;
